<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="nav align-items-center mr-auto">
      <organization-dropdown />
    </div>
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      Bookmarks Container
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <chat v-if="$can('CHAT_VIEW')" />
      <select-measurement class="mr-1" />
      <locale />
      <!-- <search-bar /> -->
      <dark-Toggler />
      <!-- <notification-dropdown />-->
      <!-- <cart-dropdown/> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import Chat from './components/Chat.vue'
import Locale from './components/Locale.vue'
import SelectMeasurement from './components/SelectMeasurement.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import OrganizationDropdown from './components/OrganizationDropdown.vue'

export default {
  name: 'AppNavbarVerticalLayout',
  components: {
    Chat,
    Locale,
    SelectMeasurement,
    DarkToggler,
    UserDropdown,
    OrganizationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
