<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.title" class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.title) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in breadCrumbs" :key="item.text" :active="item.active" :to="item.to">
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col v-if="$route.meta.backBtn" class="content-header-right text-md-right d-md-block d-none mb-1" md="3" cols="12">
      <b-button v-ripple.400 class="btn-icon" @click="$router.go(-1)">
        <feather-icon icon="ArrowLeftIcon" />
        {{ $t('action.back') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadcrumb === 'function') {
        return this.$route.meta.breadcrumb.call(this, this.$route)
      }
      return this.$route.meta.breadcrumb
    },
  },
}
</script>
