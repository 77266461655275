<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <font-awesome-icon class="mr-50" icon="ruler" />
      <span class="d-none d-md-inline-block ml-50 text-body">{{ translate(measurement) }}</span>
    </template>

    <b-dropdown-item v-for="system in measurements" :key="system" @click="setMeasurement(system)">
      <span class="ml-50">{{ translate(system) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import MEASUREMENT from '@/constants/measurement'

export default {
  name: 'SelectMeasurement',

  methods: {
    translate(system = 'metric') {
      if (this.$te(`measurement.system.${system}`)) {
        return this.$tc(`measurement.system.${system}`, 1)
      }

      return this.$options.filters.capitalize(this.measurement)
    },
    setMeasurement(system) {
      this.measurement = system
      window.location.reload()
    },
  },
  setup() {
    const measurements = Object.keys(MEASUREMENT)
    const { measurement } = useAppConfig()

    return {
      measurements,
      measurement,
    }
  },
}
</script>
