<template>
  <b-nav-item-dropdown v-if="user.organizations" left toggle-class="d-flex align-items-center ">
    <template #button-content>
      <b-avatar
        size="40"
        variant="light-secondary"
        :badge="organizationsLength"
        badge-left
        badge-top
        badge-offset="-0.25em"
        badge-variant="info"
      >
        <feather-icon icon="HomeIcon" size="22" />
      </b-avatar>
      <div v-if="$store.getters['app/mdAndUp']" class="organisation-nav">
        <p class="organization-name organization-color font-weight-bolder mb-0 ml-50">
          {{ selectedOrganization.name }}
        </p>
      </div>
    </template>

    <b-dropdown-item
      v-for="organization in user.organizations"
      :key="organization.id"
      @click="setSelectedOrganisation(organization)"
    >
      <span class="text-nowrap">{{ organization.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'OrganizationDropdown',

  computed: {
    ...mapState('auth', ['user', 'selectedOrganization']),
    organizationsLength() {
      return this.user.organizations.length.toString()
    },
  },
  methods: {
    ...mapActions('auth', ['setSelectedOrganization']),
    setSelectedOrganisation(organization) {
      this.$bvModal
        .msgBoxConfirm(this.$t('organization.modal.edit'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            this.setSelectedOrganization(organization)
            if (this.$route.name !== 'dashboard') {
              this.$router.push({ name: 'dashboard' })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.organization-color {
  color: #6e6b7b !important;
}
</style>
