<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div v-if="$store.getters['app/mdAndUp']" class="user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user.firstName || user.lastName }}
        </p>
      </div>
      <b-avatar
        size="40"
        :src="user.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!user.firstName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.profile') }}</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="$router.push({ name: 'account' })">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.profile') }}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.tasks') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'chat' }">
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span> {{ $t('navigation.chat') }}</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <!-- <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.settings') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.pricing') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.faq') }}</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span> {{ $t('topbar.dropdown.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserDropdown',

  data() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'isAuthorized']),
  },

  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>
