<template>
  <b-nav>
    <b-nav-item class="mr-25">
      <b-button variant="link" class="p-0" @click="chat()">
        <feather-icon :badge="unreadMessages" badge-classes="bg-danger" :class="`text-${isActive ? 'primary' : 'body'}`" icon="MessageCircleIcon" size="21" />
      </b-button>
    </b-nav-item>
  </b-nav>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Chat',
  data() {
    return {
      isActive: false,
    }
  },

  computed: {
    ...mapState('chat', ['unreadMessages']),
  },
  watch: {
    '$route.name': {
      handler(routeName) {
        this.getUnreadMessages()
        this.isActive = routeName === 'chat'
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('chat', ['fetchUnreadMessages']),
    chat() {
      if (this.$route.name !== 'chat') {
        this.$router.push({ name: 'chat' })
      }
    },
    getUnreadMessages() {
      this.fetchUnreadMessages()
    },
  },
}
</script>
