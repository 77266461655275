<template>
  <div class="mb-0 mt-25 d-flex flex-xl-row flex-wrap justify-content-between">
    <div class="d-flex justify-content-center justify-content-md-start flex-grow-1">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link :to="{ name: 'home' }" class="ml-25 mb-25" target="_blank">MyFlight Connect</b-link>
      <span class="d-none d-sm-inline-block">, {{ $t('footer.all_rights_reserved') }}</span>
    </div>

    <div class="d-flex flex-grow-1 justify-content-center justify-content-md-between flex-wrap">
      <b-link :to="{ name: 'privacy-policy' }" class="mx-25" target="_blank">{{ $t('navigation.privacy_policy') }}</b-link>
      <b-link :to="{ name: 'cookies-policy' }" class="mx-25" target="_blank">{{ $t('navigation.cookies_policy') }}</b-link>
      <b-link v-if="selectedOrganization.seller || selectedOrganization.operator" :to="{ name: 'terms-of-services' }" class="mx-25" target="_blank">{{ $t('navigation.terms_of_services') }}</b-link>
      <b-link :to="{ name: 'commercial-terms' }" class="mx-25" target="_blank">{{ $t('navigation.commercial_terms') }}</b-link>
      <b-link :to="{ name: 'terms-of-use' }" class="mx-25" target="_blank">{{ $t('navigation.terms_of_use') }}</b-link>
      <b-link :to="{ name: 'legal-notices' }" class="mx-25" target="_blank">{{ $t('navigation.legal_notices') }}</b-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
}
</script>
